$black: #1c1c29;
$blue: #6e738c;
$lightBlue: #828191;
$gray: #798292;
$white: #f0f0f8;

$text-large: 2em;

$particleSize: 20vmin;
$animationDuration: 120s;
$amount: 20;
