@import '../../../variables.scss';

.card {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px;
  background-color: $black;
  border-radius: 10px;
  width: 80%;
  opacity: 0.7;

  .title {
    font-size: $text-large;
    text-align: center;
  }
  .cardBody {
    width: 100%;
    margin-top: 30px;
  }
}
