@import '../../variables.scss';

#layout {
  * {
    color: $white;
  }
  .content {
    height: 100vh;
    overflow: scroll;
    // opacity: 0.6;
    // background-image: radial-gradient(#405fb6 0.5px, transparent 0.5px),
    //   radial-gradient(#405fb6 0.5px, #e5e5f7 0.5px);
    // background-size: 20px 20px;
    // background-position: 0 0, 10px 10px;
  }

  .background {
    width: 100vw;
    height: 100vh;
    background: #49306e; //#3e1e68;
    position: absolute;
    overflow: hidden;
    z-index: -1;
  }

  .background span {
    width: $particleSize;
    height: $particleSize;
    border-radius: $particleSize;
    backface-visibility: hidden;
    position: absolute;
    animation-name: move;
    animation-duration: $animationDuration;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    $colors: (#583c87, #e45a84, #ffacac);
    @for $i from 1 through $amount {
      &:nth-child(#{$i}) {
        color: nth($colors, random(length($colors)));
        top: random(100) * 1%;
        left: random(100) * 1%;
        animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
        animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
        transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
        $blurRadius: (random() + 0.5) * $particleSize * 0.5;
        $x: if(random() > 0.5, -1, 1);
        box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
      }
    }
  }

  @keyframes move {
    100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
    }
  }
}

#layoutDark {
  * {
    color: $white;
  }
  .content {
    min-height: 100vh;

    background-image: radial-gradient(#405fb6 0.5px, transparent 0.5px),
      radial-gradient(#405fb6 0.5px, $black 0.5px);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
  }
}
