@import '../../variables.scss';

#divHome {
  #divBegruesung {
    padding-top: 50px;
    z-index: 1;
    text-align: center;
    .labelSmall {
      font-size: 1em;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .labelBig {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 3em;
    }
  }
  .buttonWrapper {
    width: 40%;
    margin: auto;
    margin-top: 40px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    .button {
      text-align: center;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;
    }
    .btnTransparent {
      background-color: $blue;
      // border: 1px solid $black;
    }
    .btnBackground {
      background-color: $black;
      color: white;
      // border: 1px solid $black;
    }
  }
  .themeSwitchWrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .cardContent {
    display: flex;
    flex-wrap: wrap;
    flex: 2 1 200px;
    padding: 50px;
    margin: auto;
    justify-content: center;
    gap: 30px;
    div {
      width: 40%;
      text-align: left;
      display: grid;
      grid-gap: 10px;

      display: flex;
      align-items: center;
    }
  }
}
