* {
  font-family: sans-serif;
}

body {
  // opacity: 0.6;
  // background-image: radial-gradient(#405fb6 0.5px, transparent 0.5px),
  //   radial-gradient(#405fb6 0.5px, #e5e5f7 0.5px);
  // background-size: 20px 20px;
  // background-position: 0 0, 10px 10px;
}
